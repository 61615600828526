<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">统计</h1>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="上报月份">
              <a-month-picker
                :allowClear="false"
                :locale="locale"
                placeholder="请选择月份"
                @change="dateChange"
                :defaultValue="moment(currentMonth, 'YYYY-MM')"
              />
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 220px"
                allowClear
                :treeData="deptList"
                v-model="searchParams.departmentId"
                @change="search"
                placeholder="请选择所属部门"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="exportReport" icon="export">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :loading="tableLoading"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="initColumns"
        :dataSource="tableData"
        :scroll="{x:1500}"
        :customRow="handleView">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="reason" slot-scope="text, record">
          {{ record.fromAddress }}{{ record.reason }}
        </template>
      </a-table>
    </a-card>
    <a-drawer
      title="上报详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="600px"
      :visible="detailDrawer"
      :mask="false">
      <damage-report-detail ref="viewRef"></damage-report-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { statisticsColumns } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import DamageReportDetail from './Detail'
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
  import moment from 'moment'
  import VueCookie from 'vue-cookie'
  import '../../../plugins/fileDownload/download.min'
  import { DEFAULT_X_AUTH_TOKEN } from '../../../store/mutation-types'

  export default {
    name: 'StatisticsList',
    components: { PageLayout, DamageReportDetail },
    data () {
      return {
        moment,
        locale,
        detailDrawer: false,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          reportDate: moment().format('YYYY-MM'),
          departmentId: null
        },
        initColumns: statisticsColumns(),
        tableData: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        currentMonth: moment().format('YYYY-MM'),
        tableLoading: false,
        deptList: []
      }
    },
    created () {
      this.loadDept()
      this.search()
    },
    methods: {
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      search () {
        this.restPage()
        this.loadTableData()
      },
      loadTableData () {
        this.tableLoading = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.report_statistics,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
            this.tableLoading = false
          }
        })
      },
      restPage () {
        this.searchParams.number = 0
      },
      /*表格页数发生变化*/
      tableChange (pagination, filters, sorter) {
        this.setSearchParamsPage(pagination)
        this.loadTableData()
      },
      setSearchParamsPage (pagination) {
        this.searchParams.number = pagination.current - 1
        this.searchParams.size = pagination.pageSize
      },
      dateChange (date, dateString) {
        this.searchParams.reportDate = dateString
        this.search()
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      exportReport () {
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.export,
          noTips: true,
          data: this.searchParams,
          success: (data) => {
            let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
            const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
            if (!xAuthToken) {
              this.$Message.error({
                closable: true,
                content: '下载凭证丢失，请重新登录！',
                duration: 3
              })
              return
            }
            downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
            // eslint-disable-next-line
            downloadFile(downUrl)
          }
        })
      }
    }
  }
</script>
